import React, { useState } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
// import appwork from '../../images/appwork.svg';
// import artefy from '../../images/artefy.svg';
// import column from '../../images/column.svg';
// import crise from '../../images/crise.svg';
// import dasmen from '../../images/dasmen.svg';
// import homepie from '../../images/homepie.svg';
// import invoblox from '../../images/invoblox.svg';
// import invochat from '../../images/invochat.svg';
// import invocom from '../../images/invocom.svg';
// import invogames from '../../images/invogames.svg';
// import involearn from '../../images/involearn.svg';
// import invozone from '../../images/invozone.svg';
import logo from '../../images/logo.svg';
// import mintclub from '../../images/mintclub.svg';
// import paraswap from '../../images/paraswap.svg';
// import practimyze from '../../images/practimyze.svg';
// import quick from '../../images/quick.svg';
// import revbits from '../../images/revbits.svg';
// import shield from '../../images/shield.svg';
// import travlytix from '../../images/travlytix.svg';
// import tunevu from '../../images/tunevu.svg';
// import viewpro from '../../images/viewpro.svg';
// import waybase from '../../images/waybase.svg';
import * as styles from '../../style/Tabs/Project.scss';
import ProjectImg from '../Image/ProjectImg';

const Project = () => {
  const card = [
    {
      img: 'invozone.png',
      tag: 'invo_projects',
      title: 'InvoZone',
      text1: 'Software Development Consulting for Innovators.',
      text2:
        'InvoZone offers software development consulting services for businesses, startups, and enterprises. Partner with a reliable software development company to scale up your engineering capacity.',
      url: 'http://invozone.com/',
      cate: 'web',
    },
    {
      img: 'invoblox.png',
      tag: 'invo_projects',
      url: 'http://invoblox.com/',
      title: 'InvoBlox',
      text1: 'Blockchain Development Services',
      text2:
        "Avoid overheads and timeouts with InvoBlox's top-tier blockchain development services. Let’s create a safer, and trustless ecosystem for real-world blockchain applications together.",
      cate: 'web',
    },
    {
      img: 'invogames.png',
      tag: 'invo_projects',
      url: 'http://invogames.com/',
      title: 'InvoGames',
      text1: 'PoweringGame and Metaverse Development with AI',
      text2: 'Harness the power of game technology and build an enthralling gameplay experience.',
      cate: 'mobile',
    },
    {
      img: 'involearn.png',
      tag: 'invo_projects',
      title: 'InvoLearn',
      url: 'http://involearn.io/',
      text1: 'A Learning Opportunity For All Tech Enthusiasts!',
      text2:
        'Polish your skills and get a taste of the corporate world Learn and grow your expertise in the IT industry without putting a dent in your savings.',
      cate: 'mobile',
    },
    {
      img: 'grisemetamoonverse.png',
      tag: 'invo_projects',
      title: 'GRISE MetaMoonverse',
      url: 'http://grisemetamoonverse.io/',
      text1: 'Artificial Intelligence for Cryptocurrency Price Prediction',
      text2:
        'Private traders who want to receive hints from the system of artificial intelligence and a forecast on the trend of cryptocurrency prices; Professional companies that need to evaluate a comprehensive forecast for the whole real-time cryptocurrency market, portfolio management, investments, risk management, etc.',
      cate: 'game',
    },
    {
      img: 'artefy.png',
      tag: 'invo_projects',
      url: 'https://artefy.io/',
      title: 'Artefy',
      text1: 'Collect Licensed Digital Artwork from the Masters of Fantasy, Sci-Fi & Horror',
      text2:
        'Digital collectables are as varied as the passions, lifestyles and hobbies from which they come. Find works, and communities that relate to you. From fantasy art to music to sport or gaming, wherever your passion lives, Artefy has you covered.',
      cate: 'game',
    },
    {
      img: 'paraswap.png',
      tag: 'invo_projects',
      title: 'ParaSwap',
      url: 'http://paraswap.io/',
      text1: 'Best Prices in DeFi for Traders & dApps',
      text2:
        'We are the leading DeFi aggregator that unites the liquidity of decentralized exchanges and lending protocols into one comprehensive and secure interface and APIs.',
      cate: 'block',
    },
  ];
  const card2 = [
    {
      img: 'invochat.png',
      tag: 'invo_products',
      title: 'InvoChat',
      url: 'http://invochat.io/',
      text1: 'Effortless online collaboration tool for in-house and remote teams',
      text2:
        'Catch up on important conversations and say hi to quick and productive collaboration on tasks & projects through secure chat with your teammates, file attachments, video calls, and more - all through a single platform.',
    },
    {
      img: 'invocom.png',
      tag: 'invo_products',
      title: 'InvoCom',
      url: 'http://invocom.io/',
      text1: 'Qualify Leads and Automate Customer Support with AI-Powered Chatbot',
      text2:
        'InvoCom is constructed on a seamless AI/ML technology that assures users real-time support, engagement, and long-lasting communication ties. InvoCom believes in constant innovation and accelerated development that could boost your business in a very short span',
    },
    {
      img: 'quickxi.png',
      tag: 'invo_products',
      title: 'Quickxi',
      url: 'http://quickxi.com/',
      text1: 'Feature-Rich Ready Made Mobile Applications At Your Disposal!',
      text2:
        'Thinking of developing your own grocery store or restaurant app? Now cut back on development time & costs with Quickxi. Enjoy already developed applications with on-demand customization options. Just insert your content, sit back and relax!',
    },
    {
      img: 'mintsclub.png',
      tag: 'invo_products',
      title: 'MintsClub',
      url: 'http://mintsclub.io/',
      text1: 'Discover, collect, and sell Extraordinary NFTs',
      text2:
        'MintsClub is a 21st-century entry in the open trading world of NFT, token, DApp, and Defi development. Minting and marketplace development are two forte of MintsClub enabling easy converting of your digital assets into non-fungible tokens or NFTs.',
    },
  ];
  const card3 = [
    {
      img: 'viewpro.png',
      tag: 'invo_services',
      title: 'Lets build Cities together',
      url: 'http://viewprogis.com/',
      text1: 'Effortless online collaboration tool for in-house and remote teams',
      text2:
        'GeoZone is an easy to use inherently 3D Zoning tool designed for City Planners to assist them to decipher complex zoning ordinance with ease and accuracy.',
    },
    {
      img: 'appworkco.png',
      tag: 'invo_services',
      title: 'AppWork',
      url: 'http://appworkco.com/',
      text1: 'A Solution for Property Managers, Buil by Property Managers.',
      text2:
        'AppWork = Happy Teams + Happy Residents Happy people stick around. Improve resident and employee retention. ',
    },
    {
      img: 'tunevu.png',
      tag: 'invo_services',
      title: 'Tunevu',
      url: 'http://tunevu.com/',
      text1: 'Make discovering and engaging with your podcast a seamless experience',
      text2:
        'Create greater discovery and engaging experiences for audiences as they listen to your Podcast! ',
    },
    {
      img: 'residential.png',
      tag: 'invo_services',
      title: 'Dasmen Residential',
      url: 'http://dasmenresidential.com/',
      text1:
        'Dasmen Residential LLC is a privately held real estate investment and management firm that owns and operates multi-family properties in major cities throughout the United States.',
      text2:
        'We make opportunistic investments in growth markets and employ a range of strategies to create value and sustain long term asset appreciation. We seek to continue exceeding our goal of providing outstanding risk adjusted returns to our investors and clients and providing the best possible living experience in our communities. ',
    },
    // {
    //   img: 'travlytics.png',
    //   tag: 'invo_services',
    //   title: 'Travlytix',
    //   url: 'http://travlytix.ai/',
    //   text1:
    //     'At Travlytix, we all come to work every day because we want to solve the biggest problem in travel- data.',
    //   text2:
    //     'Airlines, OTA’s and companies associated with travel e-commerce face challenges such as data capturing, processing and in-turn utilizing that data for maximising revenue and efficiency.  ',
    // },
    {
      img: 'shieldrepublic.png',
      tag: 'invo_services',
      title: 'Shield Republic',
      url: 'http://shieldrepublic.com/',
      text1: 'Shield Republic represents an American brand which all brands aspire to imitate!',
      text2:
        'Shield Republic is a proud American lifestyle brand founded in 2016, outside Raleigh, NC. All of our apparel and merchandise is proudly designed and printed in the United States.  ',
    },
    {
      img: 'homepie.png',
      tag: 'invo_services',
      title: 'HomePie ',
      url: 'https://homepie.com/',
      text1: 'Sell your own home with technology',
      text2:
        'Homepie is an online marketplace with powerful cloud-based transaction management software built-in! ',
    },
    {
      img: 'revbits.png',
      tag: 'invo_services',
      title: 'Revbits ',
      url: 'https://www.revbits.com/',
      text1: 'Detect. Deny. Defeat.',
      text2:
        'Cyber threats are constant. Protect against them with RevBits Cybersecurity Solutions.  ',
    },
    {
      img: 'column.png',
      tag: 'invo_services',
      title: 'Column ',
      url: 'https://www.column.us/',
      text1: 'Notifying the public has never been this easy.',
      text2:
        'Column is the first collaborative public notice platform helping publishers, governments and legal services work together to inform their communities. ',
    },
    {
      img: 'waybase.png',
      tag: 'invo_services',
      title: 'waybase ',
      url: 'https://www.waybase.com/',
      text1: 'Bringing the Church together for good',
      text2:
        'WayBase helps Christian leaders work together and engage their people in meaningful ways. ',
    },
    {
      img: 'practimyze.png',
      tag: 'invo_services',
      title: 'Practimyze ',
      url: 'https://www.practimyze.com/',
      text1: 'AI-Powered Healthcare Automation Platform',
      text2:
        'Streamline practice and clinic operations, make it easier for the staff to reliably keep the doctor’s schedule full, and improve patient wait times, satisfaction and care.',
    },
  ];
  const tabs = [
    {
      // icon: logo,
      title: 'PROJECTS',
      tag: 'invo_projects',
    },
    {
      // icon: logo,
      title: 'PRODUCTS',
      tag: 'invo_products',
    },
    {
      // icon: logo,
      title: 'SERVICES',
      tag: 'invo_services',
    },
  ];

  // const [items, setItems] = useState(card);
  // const filterItems = (item1) => {
  //   const showItems = card.filter((curtEle) => {
  //     return curtEle.cate === item1;
  //   });
  //   setItems(showItems);
  // };
  return (
    <div className="Project_tabs">
      <Container>
        <h1 className="project_title">Furqan Aziz Portfolio</h1>
        <p className={styles.project_tagline}>InvoZone make your ideas looks awesome.</p>
        <Tab.Container id="left-tabs-example" defaultActiveKey={tabs[0].tag}>
          <Col>
            <div className={styles.col}>
              <Nav variant="pills" className={styles.Tabbing}>
                {tabs?.map((e, i) => (
                  <Nav.Item key={i} className="nav_item">
                    <Nav.Link eventKey={e?.tag}>
                      <div className={styles.text}>
                        {/* <img src={e.icon} className="invoicon" /> */}
                        <p className="invo_title">{e?.title}</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
          {/* <Col>
            <ul className="d-flex justify-content-between ulblock">
              <li onClick={() => setItems(card)}>All</li>
              <li onClick={() => filterItems('web')}>Web</li>
              <li onClick={() => filterItems('mobile')}>Mobile</li>
              <li onClick={() => filterItems('block')}>Blockchain</li>
              <li onClick={() => filterItems('game')}>Game Development</li>
            </ul>
          </Col> */}
          <Col>
            <Tab.Content>
              {tabs?.map((e, i) => {
                const data =
                  e.tag == 'invo_projects' ? card : e.tag == 'invo_products' ? card2 : card3;
                return (
                  <Tab.Pane key={i} eventKey={e?.tag}>
                    <Row>
                      {data.map((value) => (
                        <Col key={value.title} xs={12} md={6} style={{ display: 'flex' }}>
                          <Card className="card">
                            <div className="img-fluid card_img">
                              <ProjectImg alt={value.title} filename={value.img} />
                            </div>
                            {/* <Card.Img
                              variant="top"
                              src={value.img}
                              className="img-fluid card_img"
                              placeholder="blurred"
                              loading="lazy"
                            /> */}
                            <Card.Body className="card_body">
                              <Card.Title className="card_title">{value.title}</Card.Title>
                              <Card.Text className="card_text text1">{value.text1}</Card.Text>
                              <Card.Text className="card_text text2">{value.text2}</Card.Text>
                              <a
                                href={value.url}
                                variant="primary"
                                class="btn btn-primary card_btn"
                                role="button"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Live
                              </a>
                              {/* <Button variant="primary" className="card_btn">
                                View Live
                              </Button> */}
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default Project;
