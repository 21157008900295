import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import '../style/main.scss';

const HomePage = () => {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Furqan Aziz | CEO at InvoZone & Quickxi</title>
        <html lang="en" />
        <meta
          name="description"
          content="Furqan Aziz is an award-winning tech entrepreneur, CTO, and Founder at InvoZone with over 12 years of industry experience, leading a diverse team of 500+ young professionals."
        />
      </Helmet>
      <App />
    </>
  );
};

export default HomePage;
