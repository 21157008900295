import React from 'react';
import About from './About/About';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Project from './Tabs/Project';

function App() {
  return (
    <>
      <About />
      <Project />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
