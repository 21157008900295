import React, {useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col, Button } from 'react-bootstrap';
import AboutImg from '../Image/AboutImg';

const About = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container style={{ position: 'relative', zIndex: '2' }}>
        <Button className="about_btn">About Me</Button>
        <Row className="about-wrapper">
          <Col md={8} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <h2 className="ceo_title">Furqan Aziz - CEO of Invozone</h2>
                <p className="about-wrapper__info-text ceo_para">
                  Furqan Aziz is an award-winning tech entrepreneur, CTO, and Founder at InvoZone
                  with over 12 years of industry experience, leading a diverse team of 500+ young
                  professionals. He is also an experienced developer skilled in emerging tech
                  stacks. Ever since he ventured into the software development industry - Furqan has
                  led the development of many successful, innovative, and cutting-edge software
                  solutions for a diverse global clientele. Additionally, his successful business
                  ventures opened doors to limitless opportunities in the tech world.
                  </p>
                  <p className="about-wrapper__info-text ceo_para">
                  He thoroughly enjoys the creative process of developing new products and helping
                  businesses grow in a competitive market. Furqan believes in a human-centric
                  approach that transpires into the working ethos of his company.
                  </p>
                  <p className="about-wrapper__info-text ceo_para">
                  Last but not least, he has a profound interest in nurturing young tech enthusiasts
                  which lead him to launch his own educational initiative ‘InvoLearn’ which
                  affordably fills in the gap between education and industry knowledge.
                </p>
                {/* {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )} */}
              </div>
            </Fade>
          </Col>
          <Col md={4} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="d-block about-wrapper__image">
                <AboutImg alt="profile picture" filename={'furqan-aziz.jpeg'} />
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
