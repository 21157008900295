import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { Link as Glink } from 'gatsby';

const Footer = () => {
  const networks = [
    {
      name: 'whatsapp',
      url: 'https://api.whatsapp.com/send?phone=923339771236&text=Lets%20Discuss%20Project',
    },
    {
      name: 'facebook',
      url: 'https://www.facebook.com/furqanataziz',
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/Furqanataziz',
    },
    {
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/furqan-aziz/',
    },
    {
      name: 'github',
      url: 'https://github.com/utf4',
    },
  ];

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="about" smooth duration={1000} >
            <i className="fa fa-angle-up fa-2x" aria-hidden="true"  />
            <p className="backtotop">Back To Top</p>
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { name, url } = network;
              return (
                <a
                  key={name}
                  href={url || 'https://github.com/cobidev/gatsby-simplefolio'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()} - All Rights Reserved By{' '}
          <Glink to="/" smooth duration={1000}>
            Furqan Aziz
          </Glink>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
